import React, { useRef, useState, useEffect, createContext } from "react";
import useResizeObserver from "use-resize-observer";

export const ThemeContext = createContext({
  layoutRef: null,
  width: 0,
  isFirstTime: false,
  setIsFirstTime: () => {},
  mobileMenuState: false,
  setMobileMenuState: () => {},
  isScrolled: false,
  setIsScrolled: () => {},
});

export const ThemeProvider = ({ children }) => {
  const layoutRef = useRef(null);
  const { width } = useResizeObserver({ ref: layoutRef });
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [mobileMenuState, setMobileMenuState] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    setIsFirstTime(sessionStorage.getItem("isFirstTime") === null);
  }, []);

  useEffect(() => {
    if (isFirstTime) {
      sessionStorage.setItem("isFirstTime", "false");
    }
  }, [isFirstTime]);

  return (
    <ThemeContext.Provider
      value={{
        layoutRef,
        width,
        mobileMenuState,
        setMobileMenuState,
        isScrolled,
        setIsScrolled,
        isFirstTime,
        setIsFirstTime,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
