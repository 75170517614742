import React, { useContext, useEffect } from "react"
import { AnimatePresence, motion } from "framer-motion"
import Header from "./Header"
import { ThemeContext } from "./context/ThemeContext"
import SplashScreen from "./SplashScreen"

const Layout = ({ children, location }) => {
  const { isFirstTime, layoutRef, setMobileMenuState } =
    useContext(ThemeContext)

  useEffect(() => {
    setMobileMenuState(false)
  }, [location, setMobileMenuState])

  return (
    <>
      <AnimatePresence mode="wait">
        {isFirstTime ? (
          <SplashScreen />
        ) : (
          <motion.div
            ref={layoutRef}
            className="relative h-screen-ios min-h-screen-ios font-body"
          >
            <Header location={location} />
            <AnimatePresence mode="sync">{children}</AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default Layout
