import { useStaticQuery, graphql } from "gatsby";

export const useSplashScreen = () => {
  const data = useStaticQuery(graphql`
    query splashScreen {
      wpPage(slug: { eq: "homepage" }) {
        id
        splashScreen {
          vimeoIds {
            id
          }
        }
      }
    }
  `);

  return data;
};
