import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"
import Logo from "../../assets/logo/a25-logo-w.svg"
import Hamburger from "./Hamburger"
import { ThemeContext } from "./context/ThemeContext"
import { useIsMetodoVisible } from "../hooks/useIsMetodoVisible"

const data = [
  { title: "Progetti", slug: "/progetti/" },
  { title: "Appunti", slug: "/appunti/" },
  { title: "Studio", slug: "/studio/" },
]
const Header = ({ location }) => {
  const { isFirstTime, mobileMenuState, setMobileMenuState, width } =
    useContext(ThemeContext)
  const { wpPage } = useIsMetodoVisible()

  useEffect(() => {
    setMobileMenuState(false)
  }, [width, setMobileMenuState])

  const handleTap = destination => {
    if (location.pathname === destination) {
      setMobileMenuState(false)
    }
  }

  return (
    <>
      {!isFirstTime && (
        <>
          <header className="text-white fixed z-[3] top-4 w-full">
            <nav className="flex flex-row items-center justify-between px-10">
              <div className="w-[160px] md:w-[200px] h-[65px]">
                <Link
                  to="/"
                  className="drop-shadow-md w-full h-full  flex flex-col items-center justify-center"
                >
                  <Logo />
                </Link>
              </div>
              {width > 1020 ? (
                <div className="flex-row gap-x-4 uppercase inline-flex text-white">
                  {data.map((item, index) => {
                    return (
                      <div key={`nav-link-${index}`}>
                        {item.slug !== "/appunti/" ? (
                          <Link
                            to={item.slug}
                            className="drop-shadow-md"
                            activeClassName=" border-b-2 border-white"
                          >
                            {item.title}
                          </Link>
                        ) : wpPage.ACFhomepage.isAppuntiVisible !== null &&
                          wpPage.ACFhomepage.isAppuntiVisible !== false ? (
                          <Link
                            key={`nav-link-${index}`}
                            to={item.slug}
                            className="drop-shadow-md"
                            activeClassName=" border-b-2 border-white"
                          >
                            {item.title}
                          </Link>
                        ) : (
                          <span className="opacity-50 drop-shadow-md">
                            {item.title}
                          </span>
                        )}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <Hamburger />
              )}
            </nav>
          </header>

          <AnimatePresence>
            {mobileMenuState && (
              <motion.div
                initial={{ opacity: 0, x: "-100%" }}
                animate={{ opacity: 1, x: "0" }}
                exit={{ opacity: 0, x: "-100%" }}
                transition={{ type: "tween", duration: 0.5 }}
                className="block md:hidden fixed top-0  w-full h-full z-[2]"
              >
                <div className=" pb-8 px-10 bg-bgGray flex flex-col pt-40 gap-y-4 h-full text-4xl">
                  {data.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item.slug !== "/appunti/" ? (
                          <Link
                            key={`nav-link-${index}`}
                            to={item.slug}
                            className="border-b-2 border-black pb-2"
                            activeClassName="text-gray"
                            onClick={() => handleTap(item.slug)}
                          >
                            {item.title}
                          </Link>
                        ) : wpPage.ACFhomepage.isAppuntiVisible !== null &&
                          wpPage.ACFhomepage.isAppuntiVisible !== false ? (
                          <Link
                            key={`nav-link-${index}`}
                            to={item.slug}
                            className="border-b-2 border-black pb-2"
                            activeClassName="text-gray"
                            onClick={() => handleTap(item.slug)}
                          >
                            {item.title}
                          </Link>
                        ) : (
                          <span className="border-b-2 border-black pb-2 opacity-50">
                            {item.title}
                          </span>
                        )}
                      </React.Fragment>
                    )
                  })}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
    </>
  )
}

export default Header
