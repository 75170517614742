import { useStaticQuery, graphql } from "gatsby";

export const useIsMetodoVisible = () => {
  const data = useStaticQuery(graphql`
    query isappuntivisible {
      wpPage(slug: { eq: "homepage" }) {
        id
        ACFhomepage {
          isAppuntiVisible
        }
      }
    }
  `);

  return data;
};
